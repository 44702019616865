import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export default function Brand({ marka, slug }) {
  return (
    <BrandWrapper>
      <Link
        to={`/${slug}`}
        style={{ cursor: "pointer", textDecoration: "none" }}
      >
        <h1>{marka}</h1>
      </Link>
    </BrandWrapper>
  )
}

const BrandWrapper = styled.div`
  grid-area: brand;
  width: 100%;

  /* margin: 1.5rem auto 1rem;
  margin: 0.25rem; */
  font-size: 90%;

  @media (min-width: 600px) {
    margin: 0.25rem;
    font-size: 110%;
  }

  h1 {
    font-weight: normal;
    font-family: "Spartan", sans-serif;
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;

    position: relative;

    /* background-color: black; */
    background-color: #212121;
    color: #fff;

    /* padding: 0.5rem 1rem; */
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
  }

  @media (min-width: 600px) {
    font-weight: 200;
  }

  h1::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;

    width: 100%;
    height: 30px;

    z-index: -10;

    background: repeating-linear-gradient(
      -90deg,
      #e87f24 0,
      #e87f24 calc(1 / 8 * 100%),
      #f7921e calc(2 / 8 * 100%),
      #fcb712 calc(3 / 8 * 100%),
      #fedc00 calc(4 / 8 * 100%),
      #e0d81d calc(5 / 8 * 100%),
      #becc2f calc(6 / 8 * 100%),
      #7cba6d calc(7 / 8 * 100%),
      #239a87 calc(8 / 8 * 100%)
    );

    @media (min-width: 600px) {
      top: -15px;
    }
  }

  /* h1::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;

    width: 100%;
    height: 64px;

    z-index: -10;

    background: repeating-linear-gradient(
      90deg,
      #e87f24 0,
      #e87f24 calc(1 / 8 * 100%),
      #f7921e calc(2 / 8 * 100%),
      #fcb712 calc(3 / 8 * 100%),
      #fedc00 calc(4 / 8 * 100%),
      #e0d81d calc(5 / 8 * 100%),
      #becc2f calc(6 / 8 * 100%),
      #7cba6d calc(7 / 8 * 100%),
      #239a87 calc(8 / 8 * 100%)
    );

    @media (min-width: 600px) {
      height: 68px;
    }
  } */
`
