import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Rum from "./Rum"

const getPosts = graphql`
  query {
    posts: allContentfulBlog(sort: { fields: published, order: DESC }) {
      edges {
        node {
          marka
          cena
          coo
          typ
          styl
          published(formatString: "LL", locale: "pl")
          updatedAt
          slug
          next
          producent
          regulacje
          id: contentful_id
          imagefront {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

//         published(formatString: "LL", locale: "pl")

const RumList = () => {
  const { posts } = useStaticQuery(getPosts)

  /*
  const publishedDate = new Date(posts.edges[0].node.updatedAt)
  // based on published date calculate date for next publish
  const nextDate = publishedDate.setDate(publishedDate.getDate() + 14)
  const nowDate = Date.now()
  // days left until next publish
  const remainDays = Math.floor((nextDate - nowDate) / (1000 * 60 * 60 * 24))
  */

  return (
    <>
      {/* <Title>
        <h3>nowa recenzja</h3>
        <h3>
          już {remainDays > 1 ? "za" : ""}{" "}
          <span>{remainDays > 1 ? remainDays : ""}</span>{" "}
          {remainDays > 1 ? "dni" : "jutro"}
        </h3>
      </Title> */}

      {posts.edges[0].node.next ? (
        <Title>
          <h3>coming next:</h3>
          <h3>{posts.edges[0].node.next}</h3>
        </Title>
      ) : null}

      <Wrapper>
        {posts.edges.map(({ node }) => {
          return <Rum key={node.id} rum={node} />
        })}
      </Wrapper>
    </>
  )
}

export default RumList

const Title = styled.div`
  margin: 1rem auto 2rem;
  margin-bottom: -1rem;

  max-width: 960px;
  margin-left: 10%;

  h3 {
    text-align: left;

    font-family: "Montserrat", sans-serif;

    text-transform: capitalize;
    font-size: 1rem;

    @media (min-width: 700px) {
      font-size: 1.5rem;
    }

    @media (min-width: 1050px) {
      margin-left: 15%;
      /* text-align: center; */
    }
  }

  h3:nth-child(2) {
    font-weight: normal;
    /* font-weight: 100; */

    font-family: "Spartan", sans-serif;
    font-family: "Montserrat", sans-serif;
  }
`

const Wrapper = styled.section`
  /* width: 80%; */
  margin: 0 auto;
  /* max-width: 90%; */

  /* @media (min-width: 1100px) {
    width: 70%;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }

  @media (min-width: 1300px) {
    width: 50%;
  } */
`
