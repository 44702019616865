import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import RumList from "../components/RumList"

const IndexPage = () => (
  <Layout>
    <SEO title="Blog o rumie" />

    <RumList />
  </Layout>
)

export default IndexPage
