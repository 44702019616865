import React from "react"

import styled from "styled-components"

export default function Info({ producent, cena, coo, regulacje, styl, typ }) {
  return (
    <ProfileWrapper>
      <Section>
        <Row>
          <Left>
            <p>Producent: </p>
          </Left>
          <Right>{producent}</Right>

          <Left>
            <p>Cena: </p>
          </Left>
          <Right>{cena} &#8364;</Right>
        </Row>
      </Section>

      <Section>
        <Row>
          <Left>
            <p>Terroir: </p>
          </Left>
          <Right>{coo}</Right>
          <Left>
            <p>Regulacje: </p>
          </Left>
          <Right>{regulacje}</Right>
        </Row>
      </Section>

      <Section>
        <Row>
          <Left>
            <p>Styl: </p>
          </Left>
          <Right>{styl}</Right>
          <Left>
            <p>Klasyfikacja: </p>
          </Left>
          <Right>{typ}</Right>
        </Row>
      </Section>
    </ProfileWrapper>
  )
}

const ProfileWrapper = styled.div`
  margin-top: 2rem;
  grid-area: info;

  font-family: var(--montserrat);

  @media (min-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  width: 100%;
  margin: 10px auto 30px;

  @media (min-width: 600px) {
    width: 96%;
  }

  h3 {
    font-family: "Spartan", sans-serif;

    text-transform: uppercase;
    text-align: right;
    margin-bottom: 10px;
  }
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 110px 1fr;

  @media (min-width: 900px) {
    grid-template-columns: 120px 1fr;
  }

  align-items: end;
  /* align-self: end; */
`

const Left = styled.div`
  text-align: right;
  margin-top: 4px;

  /* display:grid in Row, no need to adjust with flex? */

  /* display: flex;
  justify-content: end;
  align-items: flex-start; */

  /* align-items: center; */

  /* font-size: 1.2rem; */
  /* font-size: 80%; */
  font-family: "Spartan", sans-serif;
  /* padding-bottom: 2px; */

  p {
    font-size: 1rem;

    @media (min-width: 800px) {
      font-size: 1.1rem;
    }
    @media (min-width: 900px) {
      font-size: 1.2rem;
    }
  }
`
const Right = styled.div`
  padding-left: 10px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  /* font-size: 110%; */
  /* align-self: flex-end; */

  font-size: 1rem;

  @media (min-width: 800px) {
    font-size: 1.1rem;
  }

  @media (min-width: 900px) {
    font-size: 1.2rem;
  }
`
